import React, { Fragment } from 'react'
import Main from '../../layouts/Main'
import styles from './Subpage.module.scss'
import cn from 'classnames'
import {
  Header,
  Hero,
  CategoryIntro,
  CategorySection
} from '../../components'

const Sub = ({ cat }) => {
  const { description, sections } = cat

  return (
    <Fragment>
      <CategoryIntro description={description} />
      {sections.map(({ title, description, products }, i) => {

        return (
          <CategorySection
            key={i}
            title={title}
            description={description}
            cards={products}
          />
        )
      })}
    </Fragment>
  )
}

const Subpage = ({ pageContext }) => {
  const { cat } = pageContext

  return (
    <Main>
      <Header />
      <Hero />
      <section className={cn('container', styles.container)}>
        <Sub cat={cat} />
      </section>
    </Main>
  )
}

export default Subpage
